<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>
<!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/61d7d995f7cf527e84d0de94/1fopijsn8';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
</script>
<!--End of Tawk.to Script-->
<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  @import url('https://fonts.googleapis.com/css?family=Roboto');
  // @import url('https://fonts.googleapis.com/css?family=Arya');
  // @import url('https://fonts.googleapis.com/css2?family=Fjalla One');
  html, body {
  font-family: 'Roboto';
  // font-family: ui-monospace;
}

#app {
  font-family: 'Roboto', sans-serif;
  // font-family: ui-monospace;
}
.mainbg{
  background-image: url("/main_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.url_text{
  font-family: 'Oswald', sans-serif;
  color: #000;
  font-weight: 600;
}
.link {
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
// td{
//   max-width: 20vh !important;
// }
.modal-xl {
    max-width: 90% !important;
}
.dateTimePickerWrapper .calendarTrigger[data-v-9336155c]{
  min-width: auto !important;
}
.focus_guide{
  // border: 5px solid #0057ff;
    box-shadow: 0px 2px 10px 8px #3d9985;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    background: rgb(243 243 243 / 33%);
    min-width: 80%;
}
.v-step__header{
    margin: -1rem -1rem 0.5rem;
    padding: 0.5rem;
    background-color: #3d9985 !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 18px;
    font-weight: 700;
    // border-radius: 25px 25px 0px 0px !important;
    text-align: center !important;
    color: #ffffff;
}
.v-step{
  background: #ffffff !important;
  max-width: 150vh !important;
  // border-radius: 25px !important;
  text-align: left !important;
  color: #000 !important;
  box-shadow: 0px 5px 50px 22px #000000a8 !important;
  font-size:18px;
}
.v-step__arrow--dark::before{
  background: #ffffff !important;
  margin-left: 0px !important;
}
.form-control{
  color: #000 !important;
}
.res_text {
  display: inline-block;
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  cursor: pointer;
}
.res_text:hover {
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
